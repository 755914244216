import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { multiClientMiddleware } from 'redux-axios-middleware';
import api from "./actions/api";
import routes from './routes/routes';
import rootReducer from './reducers';
import {configureSentry} from './helpers/functions';

configureSentry({
    dsn: "https://79f6d9b9eb57411ca74cf23c090ad93d@sentry.4-com.pro/23",
    dev: "supplier.testmexcar.ru",
    prod: "supplier.mexcar.ru"
});

const axiosMiddlewareOptions = {
    interceptors: {
        request: [
            (action, config) => {
                if (localStorage.token || localStorage.token_res) {
                    let token = localStorage.token ? localStorage.token : localStorage.token_res;
                    config.headers['Authorization'] = 'Token ' + token
                }
                return config;
            }
        ]
    }
};

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const createStoreWithMiddleware = applyMiddleware(multiClientMiddleware(api, axiosMiddlewareOptions), appRouterMiddleware)(createStore);
const store = createStoreWithMiddleware(rootReducer(history), {}, window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);


ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history} children={routes}/>
    </Provider>,
    document.getElementById('wrapper')
);

