let BASE_URL, SECOND_URL;

if(window.location.host === 'localhost:3000' || window.location.host === "supplier.testmexcar.ru") {
    BASE_URL = 'https://api.testmexcar.ru/api/v0';
} else {
    BASE_URL = 'https://api.mexcar.ru/api/v0';
}

SECOND_URL = '';
export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
