import React, { Component } from 'react';
import { SubmissionError, reduxForm, stopSubmit } from 'redux-form';
import TransitionedBlock from '../../../HelperComponents/TransitionedBlock/TransitionedBlock';
import BackLink from '../../../BackLink/BackLink';
import DefaultButton from '../../../Buttons/DefaultButton/DefaultButton';
import IconDeleteFile from '../../../../assets/image/delete_file.png';
import Iconfile from '../../../../assets/image/file.png';
import IconAlert from '../../../../assets/image/alert_amount.png';
import IconAttach from '../../../../assets/image/attach.png';
import './RequestsAdd.scss';
import IncreaseIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {isArray} from "../../../../helpers/functions";
import {bindActionCreators} from "redux";
import {getMyJapartsOrders, postCreateRequest} from "../../../../actions/PurchasesActions";
import {connect} from "react-redux";
import ErrorIcon from '@material-ui/icons/Error';
import TooltipMessage from '../../../HelperComponents/TooltipMessage/TooltipMessage';

class RequestsAdd extends Component {

    state = {
        value: '',
        activePage: 1,
        openModal: false,
        initSearch: false,
        results: {data: [], query: ''},
        hasResults: false,
        files: [],
        activeItem: {amount: 1, price: 0, id: 0, lot:0},
        requestAmount: 1,
        requestSum: 0,
        addDisabled: false,
        reDisabled: false,
        loading: false,
        comment: '',
        submitDisable: true,
        showToast: false,

    };

    submitForm = () => {
        const {postCreateRequest, history} = this.props;
        const {files, activeItem, requestAmount, requestSum, comment} = this.state;
        this.setState({loading: true});
        let formData = new FormData();
        files.map(file => {
        formData.append('files', file, file.name);
        });
        formData.append('price', requestSum);
        formData.append('ordernum', activeItem.ordernum);
        formData.append('order', activeItem.id);
        formData.append('comment', comment);
        formData.append('amount', requestAmount);


        return postCreateRequest(formData).then(res => {
        if (res.payload && res.payload.status && res.payload.status === 201) {
        this.setState({loading: false});
        history.push({pathname: '/admin/purchases', search: `tab=2&success=true`});
        } else {
        this.setState({ loading: false });
        // ошибка с бэка
        throw new SubmissionError({
        ...res.error.response.data,
        _error: res.error.response.data && res.error.response.data.error
        });
        }
        })

    };

    decreaseAmount = () => {

        const {requestAmount, activeItem} = this.state;
        let newValue = requestAmount - 1;
        if (newValue >= 1) {
            this.setState({
                requestAmount: newValue,
                addDisabled: false,
                requestSum: parseFloat(activeItem.price * newValue)
            });
        }
    };
    increaseAmount = () => {
        const {requestAmount, activeItem} = this.state;
        let newValue = parseInt(requestAmount + 1);
        this.setState({requestAmount: newValue, requestSum: parseFloat(activeItem.price * newValue)});


    };
    //value.length <= 3 &&
    handleOnKey = (e) => {
        const {results, initSearch, value} = this.state;
        if (value.length >= 3 && results.query !== value && value.indexOf(" ") === -1) {
            this.getMyJapartsOrders(value);
        } else if (value.length >= 3 && !initSearch) {
            this.setState({initSearch: true});
        } else if(value.length < 3 && value !== "" && e.keyCode === 13){
            this.getMyJapartsOrders(value);
        }else if (value === "" || isArray(results.data)) {
            if(e.keyCode === 13){
                return false
            }
            this.setState({
                initSearch: false,
                submitDisable: true,
                requestAmount: 1,
                requestSum: 0,
                activeItem: {amount: 1, price: 0, id: 0},
            });
        }
    };

    getMyJapartsOrders = (value) => {
        // сброс ошибки
        this.props.dispatch(stopSubmit('RequestsAddForm', {}))
        const {getMyJapartsOrders} = this.props;
        getMyJapartsOrders(value).then((res) => {
            if (res.payload.data) {

                this.setState({
                    initSearch: true,
                    hasResults: true,
                    results: {
                        data: res.payload.data.results,
                        query: value
                    }
                });
            } else {
                this.setState({
                    hasResults: false,
                    results: {
                        data: [],
                        query: value
                    }
                })
            }

        })
    };

    onChangeFile(e) {
                this.setState({files: this.state.files.concat(e.target.files[0])})
        e.target.value = ''
    }

    onChangeText(e) {
        let comment = e.target.value;
        this.setState({comment: comment});
        if (comment.length >= 1 && this.state.activeItem['id'] !== 0) {
            this.setState({submitDisable: false})
        } else {
            this.setState({submitDisable: true})

        }
    }

    removeFile(index) {

        let array = [...this.state.files]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({files: array});
        }
    }

    toggleSnack = () => {
        this.setState(({success}) => ({
            success: false
        }));
    };

    handleActiveItem(index) {
        let array = [...this.state.results.data]; // make a separate copy of the array
        let {requestAmount, comment} = this.state;
        let activeItem = array[index];
        this.setState({
            activeItem: activeItem,
            results: {data: [], query: ''},
            initSearch: false,
            submitDisable: comment.length <= 1,
            value: `${activeItem.id}`,
            requestSum: parseFloat(activeItem.price * requestAmount)
        },);
    }

    onChange = (e) => {
        let value = e.target.value.replace(/\D/g,'');
        this.setState({ value: value });
    };

    onClickAttach = (e) => {
        e.preventDefault()
        this.upload.click()
    };

    render() {
        const { handleSubmit, error } = this.props;
        const {files, value, requestAmount, initSearch, requestSum, activeItem, reDisabled, submitDisable, loading, comment, results} = this.state;
        return (
            <form onSubmit={handleSubmit(this.submitForm)}>
                <div className="container requests_add_page">
                    <div className="title-block">
                        <BackLink to={'/admin/purchases?tab=2'}/>
                        <h1>Создать заявку на возврат</h1>
                        <TransitionedBlock>
                            <div className="requests_add">
                                <div className="requests_add_search">
                                    <span>Номер заказа</span>
                                    <input type="text"
                                        value={value}
                                        onChange={(e) => this.onChange(e)}
                                        onKeyUp={(e) => this.handleOnKey(e)}
                                        placeholder="Введите номер заказа"
                                    />
                                    {initSearch ?
                                        <div className="search_products_list">
                                            {isArray(results.data) ?
                                                results.data.map((el, index) => {
                                                    const {id} = el;
                                                    return (
                                                        <div
                                                            className="search_product_item"
                                                            onClick={() => this.handleActiveItem(index)}
                                                            key={id}
                                                        >
                                                            <div className="search_product_item_head">
                                                                <span>{el.id}</span>
                                                            </div>
                                                            <div className="search_product_item_info">
                                                                <div>
                                                                    <span className="block_label">Товар</span>
                                                                    <p className="block_value">{el.name}</p>
                                                                </div>
                                                                <div>
                                                                    <span className="block_label">Артикул</span>
                                                                    <p className="block_value">{el.article_number}</p>
                                                                </div>
                                                                <div>
                                                                    <span className="block_label">Производитель</span>
                                                                    <p className="block_value">{el.producer}</p>
                                                                </div>
                                                                <div>
                                                                    <span className="block_label">Количество</span>
                                                                    <p className="block_value">{el.amount}</p>
                                                                </div>
                                                                <div className="block_value_currency">
                                                                    {el.price}
                                                                    <div className="ru_value"> ₽</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                : <li>Нет совпадений</li>
                                            }
                                        </div>
                                        : null}
                                </div>

                                <div className="amount_requests">
                                    <div>
                                        <span className="descriptions_amount">Количество</span>

                                        <div className="block_amount">
                                            <div className="amount">

                                                <button className="minus" disabled={activeItem.id ===0 || requestAmount <= 1}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.decreaseAmount()
                                                    }}>
                                                    <RemoveIcon/>
                                                </button>
                                                <span>{requestAmount}</span>
                                                <button className="plus" disabled={requestAmount === activeItem.amount}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        this.increaseAmount()
                                                    }}>
                                                    <IncreaseIcon/>
                                                </button>
                                            </div>
                                            <div className="info_amount"><img src={IconAlert} alt="IconAlert"/> Количество
                                                товара для оформления возврата
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price_all">
                                        <span className="descriptions_amount">Сумма</span>
                                        <div className="price">{requestSum.toFixed(2)} ₽</div>
                                    </div>
                                </div>

                                <div className="block_textarea_amount">
                                    <span>Комментарий</span>
                                    <textarea placeholder="Ваш комментарий" onChange={(e) => this.onChangeText(e)}
                                            value={comment}></textarea>
                                </div>
                                <div className="attach_file_block">
                                    <input type="file" hidden={true} ref={(ref) => this.upload = ref}
                                        onChange={(e) => this.onChangeFile(e)}/>

                                    <button className="attach_btn" onClick={(e) => this.onClickAttach(e)}>
                                        <img src={IconAttach} alt="IconAttach"/>
                                        Прикрепить файл
                                    </button>
                                    {files.map((file, index) => {
                                        return (
                                            <div className="document_info" key={index}>
                                                <img className="doc_icon" src={Iconfile} alt="Iconfile"/>
                                                <span>{file.name}</span>
                                                <button className="btn_delete_doc"
                                                        onClick={(e) => this.removeFile({index})}>
                                                    <img src={IconDeleteFile} alt="IconDeleteFile"/>
                                                </button>
                                            </div>)
                                    })}

                                </div>
                                <div className="btn-wrapper">
                                    <DefaultButton
                                        variant="outlined"
                                        classes="cancel_btn"
                                        type="link"
                                        to="/admin/purchases?tab=2"
                                    >
                                        Отмена
                                    </DefaultButton>
                                    <DefaultButton
                                        loading={loading}
                                        disabled={submitDisable}
                                        formAction
                                        variant="contained"
                                    >
                                        Создать заявку
                                    </DefaultButton>
                                    <div className="select_error_tooltip">
                                    {error && error !== null &&
                                        <TooltipMessage
                                            text={error}
                                            delay={200}
                                            error
                                        >
                                            <ErrorIcon/>
                                        </TooltipMessage>
                                    }
                                    </div>
                                </div>
                            </div>
                        </TransitionedBlock>
                    </div>
                    </div>
                </form>
        );
    }
}


RequestsAdd = reduxForm({
    form: "RequestsAddForm",
})(RequestsAdd);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getMyJapartsOrders,
        postCreateRequest
    }, dispatch);
}


export default connect(null, mapDispatchToProps)(RequestsAdd);